<template>
	<div class="agent_zhaopin">
		<el-card>
			<el-row :gutter="20" class="left">
				<el-col :span="24">
					<span style="color:#666">经理招聘</span>
				</el-col>
			</el-row>
			<el-divider></el-divider>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="输入查询">
							<el-input v-model="searchText" placeholder="请输入姓名" clearable></el-input>
						</el-form-item>
						<el-form-item label="申请时间">
							<el-date-picker v-model="datePicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
							</el-date-picker>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-table :data="techList" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="zhaopin_id" label="ID"></el-table-column>
				<el-table-column prop="jishi_name" label="姓名" style="width: 100px;"></el-table-column>
				<el-table-column prop="phone" label="手机号" :show-overflow-tooltip="true" width="120px"></el-table-column>
				<el-table-column label="关联用户" :show-overflow-tooltip="true" width="180px">
					<template slot-scope="scope">
				        <el-tag type="primary" disable-transitions>{{scope.row.nickName}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column prop="city_name" label="城市"></el-table-column>
				<el-table-column prop="is_guanli_name" label="管理经验"></el-table-column>
				<el-table-column prop="is_work_name" label="从事经理"></el-table-column>
				<el-table-column prop="baozhengjin" label="保证金" width="120px"></el-table-column>
				<el-table-column label="审核状态">
					<template slot-scope="scope">
				        <el-tag :type="scope.row.verify_status==0?'primary' : 'success'" disable-transitions>{{scope.row.verify_status_name}}</el-tag>
				    </template>
				</el-table-column>
				<el-table-column prop="verify_time" label="审核时间" width="150px"></el-table-column>
				<el-table-column prop="add_time" label="申请时间" width="150px"></el-table-column>
				<el-table-column fixed="right" label="操作" width="150px">
					<template slot-scope="scope">
						<el-row style="margin-bottom:10px" v-if="scope.row.verify_status==0">
							<el-button size="mini" type="primary" plain @click="goEdit(scope.row)">编辑</el-button>
							<el-button size="mini" type="primary" plain @click="goVerify(scope.row)">审核</el-button>
						</el-row>
						<el-row style="margin-bottom:10px" v-else>
							<el-button size="mini" type="primary" plain @click="goVerify(scope.row)">查看</el-button>
						</el-row>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 编辑对话框 -->
			<el-dialog title="招聘编辑" width="60%" top="5vh" :visible.sync="editDialog" :modal-append-to-body="false">
				<el-form ref="editForm" :rules="editRules" :model="commonForm" label-width="120px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="姓名" prop="jishi_name">
								<el-input v-model="commonForm.jishi_name" maxlength="10" placeholder="请输入姓名"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phone">
								<el-input v-model="commonForm.phone" placeholder="请输入手机号"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="邮箱" prop="email">
								<el-input v-model="commonForm.email" placeholder="请输入邮箱"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="加入城市" prop="city_id">
								<treeselect v-model="commonForm.city_id" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer_city" :show-count="true" placeholder="请选择加入城市" />
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="关联用户" prop="nickName">
								<el-input v-model="commonForm.nickName" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="团队管理经验" prop="is_guanli">
								<el-select v-model="commonForm.is_guanli" placeholder="请选择是否有团队管理经验">
								    <el-option	v-for="(item,index) in init_list.is_guanli"	
								    	:key="item.key"
								    	:label="item.value"
								    	:value="item.key">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="从事过经理" prop="is_work">
								<el-select v-model="commonForm.is_work" placeholder="请选择是否有从事过经理">
								    <el-option	v-for="(item,index) in init_list.is_work"	
								    	:key="item.key"
								    	:label="item.value"
								    	:value="item.key">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="缴纳保证金" prop="baozhengjin">
								<el-input v-model="commonForm.baozhengjin" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="备注" prop="work_profile">
								<el-input type="textarea" :rows="5" maxlength="500" show-word-limit v-model="commonForm.work_profile" placeholder="请输入备注"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24" style="text-align:center">
							<el-button type="default" @click="editDialog=false">取消</el-button>
							<el-button type="primary" @click="editSubmit">确定</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-dialog>
			<!-- 审核对话框 -->
			<el-dialog title="招聘审核" width="60%" top="5vh" :visible.sync="showDialog" :modal-append-to-body="false">
				<el-form ref="commonForm" :model="commonForm" label-width="120px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="姓名" prop="jishi_name">
								<el-input v-model="commonForm.jishi_name" maxlength="10" placeholder="请输入姓名" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="手机号" prop="phone">
								<el-input v-model="commonForm.phone" placeholder="请输入手机号" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="邮箱" prop="email">
								<el-input v-model="commonForm.email" placeholder="请输入邮箱" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="加入城市" prop="city_id">
								<treeselect v-model="commonForm.city_id" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer_city" :show-count="true" placeholder="请选择加入城市" disabled/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="关联用户" prop="nickName">
								<el-input v-model="commonForm.nickName" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="团队管理经验" prop="is_guanli">
								<el-select v-model="commonForm.is_guanli" placeholder="请选择是否有团队管理经验" disabled>
								    <el-option	v-for="(item,index) in init_list.is_guanli"	
								    	:key="item.key"
								    	:label="item.value"
								    	:value="item.key">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="从事过经理" prop="is_work">
								<el-select v-model="commonForm.is_work" placeholder="请选择是否有从事过经理" disabled>
								    <el-option	v-for="(item,index) in init_list.is_work"	
								    	:key="item.key"
								    	:label="item.value"
								    	:value="item.key">
								    </el-option>
							  	</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="缴纳保证金" prop="baozhengjin">
								<el-input v-model="commonForm.baozhengjin" disabled></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="24">
							<el-form-item label="备注" prop="work_profile">
								<el-input type="textarea" :rows="5" maxlength="500" show-word-limit v-model="commonForm.work_profile" placeholder="请输入备注" disabled></el-input>
							</el-form-item>
						</el-col>
						<div v-if="commonForm.verify_status==0">
							<el-col :span="24">
								<el-form-item label="审核结果" prop="verify_status">
									<el-radio v-model="submitForm.verify_status" label="1">通过</el-radio>
									<el-radio v-model="submitForm.verify_status" label="2">不通过</el-radio>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="审核意见" prop="verify_content">
									<el-input type="textarea" :rows="5" maxlength="300" show-word-limit v-model="submitForm.verify_content" placeholder="请输入审核意见"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" style="text-align:center">
								<el-button type="default" @click="showDialog=false">取消</el-button>
								<el-button type="primary" @click="doSubmit">确定</el-button>
							</el-col>
						</div>
						<div v-else>
							<el-col :span="12">
								<el-form-item label="审核结果" prop="verify_status">
									<el-radio disabled v-model="commonForm.verify_status" label="1">通过</el-radio>
									<el-radio disabled v-model="commonForm.verify_status" label="2">不通过</el-radio>
								</el-form-item>
							</el-col>
							<el-col :span="24">
								<el-form-item label="审核意见" prop="verify_content">
									<el-input type="textarea" disabled :rows="5" maxlength="300" show-word-limit v-model="commonForm.verify_content"></el-input>
								</el-form-item>
							</el-col>
							<el-col :span="24" style="text-align:center">
								<el-button type="default" @click="showDialog=false">取消</el-button>
							</el-col>
						</div>
					</el-row>
				</el-form>
			</el-dialog>
		</el-card>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Treeselect,Pagination
		},
		data() {
			return {
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				searchText: "",
				datePicker: "",
				total: 0,
				techList: [],
				jishi_id: 0,
				commonForm: {},
				submitForm: {
					zhaopin_id: 0,
					verify_status: '1',
					verify_content: '',
				},
				showDialog: false,
				editDialog: false,
				editRules: {
					jishi_name: [{
						required: true,
						message: "请输入姓名",
						trigger: "blur"
					}, ],
					phone: [{
						required: true,
						message: "请输入手机号",
						trigger: "blur"
					}, ],
					city_id: [{
						required: true,
						message: "请选择城市",
						trigger: "blur"
					}, ],
				},
				city_list: [],
				init_list: []
			};
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.initData();
				this.getCityList();
				this.getZhaopinList();
			}
		},
		methods: {
			initData() {
				var url = 'agent/init_data';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.init_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getCityList(){
	  			var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
	  		},
			getZhaopinList() {
				var url = 'jishi/zhaopin_list';
				let params = {
					zhaopin_type: 3,
					jishi_name: this.searchText,
					date_time: this.datePicker,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.techList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			// 搜索
			search() {
				this.getZhaopinList();
			},
			// 重置
			reset() {
				this.searchText = "";
				this.datePicker = "";
				this.getZhaopinList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getZhaopinList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getZhaopinList();
			},
			goEdit(item){
				this.commonForm = item;
				this.editDialog = true;
			},
			editSubmit() {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'jishi/addOrUpdateZhaopin';
					let params = {
						zhaopin_id: that.commonForm.zhaopin_id,
						jishi_name: that.commonForm.jishi_name,
						phone: that.commonForm.phone,
						email: that.commonForm.email,
						city_id: that.commonForm.city_id,
						is_guanli: that.commonForm.is_guanli,
						is_work: that.commonForm.is_work,
						work_profile: that.commonForm.work_profile,
					};
					that.fd_post(url, params).then((res) => {
						that.editDialog = false;
						if(res.status) {
							that.$message.success("操作成功");
							that.getZhaopinList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.editDialog = false;
						that.$message.error('网络错误');
					});
				}, 300);
			},
			goVerify(item) {
				this.commonForm = item;
				this.submitForm.zhaopin_id = item.zhaopin_id;
				this.showDialog = true;
			},
			doSubmit() {
				if(timer != null) {
					clearTimeout(timer);
				}
				timer = setTimeout(() => {
					var url = 'jishi/zhaopinVerify';
					let params = that.submitForm;
					that.fd_post(url, params).then((res) => {
						that.showDialog = false;
						if(res.status) {
							that.$message.success("操作成功");
							that.getZhaopinList();
						} else {
							that.$message.error(res.msg);
						}
					}).catch((err) => {
						that.showDialog = false;
						that.$message.error('网络错误');
					});
				}, 300);
			},
			//转换菜单数据结构
			normalizer_city(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
		},
	};
</script>
<style lang="scss" scoped>
 	.agent_zhaopin{
 		.el-row {
			text-align: left;
			margin-bottom: 20px;
		}
		.mr3 {
			margin-right: 5px;
		}
		
		.left {
			text-align: left;
			margin-bottom: 20px;
		}
 	}
</style>